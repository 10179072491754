import type { AuthUser, LoginInput, RegisterInput } from "@repo/types/auth";
import AuthAPI from "api/auth/auth";

import { LOGIN, LOGOUT, UPDATE_EMAIL } from "./actionTypes";

const AuthActions = {
  login({ emailOrUsername, isUsername, password, turnstileToken }: LoginInput) {
    return async (
      dispatch: (action: { type: string; user?: AuthUser }) => void
    ) => {
      const user = await AuthAPI.login({
        emailOrUsername,
        isUsername,
        password,
        turnstileToken,
      });

      if (user) {
        dispatch({ type: LOGIN, user });
      }
    };
  },
  logout() {
    return (dispatch: (action: { type: string }) => void) => {
      dispatch({ type: LOGOUT });
      localStorage.clear();
    };
  },
  register(newUser: RegisterInput) {
    return async (
      dispatch: (action: { type: string; user?: AuthUser }) => void
    ) => {
      const user = await AuthAPI.register(newUser);

      if (user) {
        dispatch({ type: LOGIN, user });
      }
    };
  },
  updateEmail(email: string) {
    return (dispatch: (action: { type: string; email?: string }) => void) => {
      dispatch({ type: UPDATE_EMAIL, email });
    };
  },
};

export { AuthActions };
