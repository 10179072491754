import { jwtDecode } from "jwt-decode";

export function ucFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function toHTMLTime(time) {
  return time ? time.split(" ")[1] : "";
}

export function isTokenExpired(token) {
  if (!token) return true;

  const decoded = jwtDecode(token);
  const currentTime = Date.now() / 1000;

  return decoded.exp < currentTime;
}
