import { Navigate, Outlet } from "react-router-dom";
import type { RouteObject } from "react-router-dom";
import { lazy } from "react";

// const About = lazy(() => import("./scenes/About"));
const Account = lazy(() => import("./scenes/Home/scenes/Account/Account.js"));
const Admin = lazy(() => import("./scenes/Admin/Admin.tsx"));
const AdminHome = lazy(
  () => import("./scenes/Admin/scenes/AdminHome/AdminHome.tsx")
);
const AdminLogin = lazy(
  () => import("./components/admin/AdminLogin/AdminLogin.js")
);
const AgeGroups = lazy(
  () =>
    import(
      "scenes/Admin/scenes/TournamentManager/CompetitionManager/Competition/components/AgeGroups/AgeGroups.js"
    )
);
const AllResultsHome = lazy(
  () => import("./scenes/AllResultsHome/AllResultsHome.js")
);
const AthleteManager = lazy(
  () => import("scenes/Admin/scenes/AthleteManager/AthleteManager.js")
);
const BatchEvents = lazy(
  () =>
    import(
      "./scenes/Admin/scenes/TournamentManager/CompetitionManager/BatchEvents/BatchEvents.js"
    )
);
const ChampionshipSetup = lazy(
  () =>
    import(
      "scenes/Admin/scenes/TournamentManager/CompetitionManager/Competition/components/ChampionshipSetup/ChampionshipSetup.js"
    )
);
const CheckIn = lazy(() => import("./scenes/CheckIn/CheckIn.js"));
const CheckInHome = lazy(
  () => import("scenes/CheckIn/scenes/CheckInHome/CheckInHome.js")
);
const CheckInLogin = lazy(
  () => import("scenes/CheckIn/components/CheckInLogin/CheckInLogin.js")
);
const Checkout = lazy(
  () => import("./scenes/Home/scenes/Checkout/Checkout.js")
);
const Competition = lazy(
  () =>
    import(
      "./scenes/Admin/scenes/TournamentManager/CompetitionManager/Competition/Competition.js"
    )
);
const CompetitionEdit = lazy(
  () =>
    import(
      "scenes/Admin/scenes/TournamentManager/CompetitionManager/Competition/components/CompetitionEdit/CompetitionEdit.js"
    )
);
const CompetitionEvents = lazy(
  () =>
    import(
      "scenes/Admin/scenes/TournamentManager/CompetitionManager/CompetitionEvents/CompetitionEvents.js"
    )
);
const CompetitionManager = lazy(
  () =>
    import(
      "./scenes/Admin/scenes/TournamentManager/CompetitionManager/CompetitionManager.js"
    )
);
const Display = lazy(
  () => import("components/NanduJudging/Display/Display.tsx")
);
const DrawLots = lazy(
  () => import("./scenes/Home/scenes/DrawLots/DrawLots.js")
);
// const EditNanduForm = lazy(
//   () => import("./scenes/NanduForm/components/EditNanduForm")
// );
const EditParticipant = lazy(
  () => import("components/EditParticipant/EditParticipant.tsx")
);
const ErrorMessageManager = lazy(
  () =>
    import("./scenes/Admin/scenes/ErrorMessageManager/ErrorMessageManager.js")
);
const EventCategoryManager = lazy(
  () =>
    import("./scenes/Admin/scenes/EventCategoryManager/EventCategoryManager.js")
);
const EventCategoryRestrictions = lazy(
  () =>
    import(
      "scenes/Admin/scenes/TournamentManager/CompetitionManager/Competition/components/EventCategoryRestrictions/EventCategoryRestrictions.js"
    )
);
const EventManager = lazy(
  () => import("./scenes/Admin/scenes/EventManager/EventManager.js")
);
const EventsPane = lazy(() => import("components/EventsPane/EventsPane.js"));
const FeeManager = lazy(
  () => import("./scenes/Admin/scenes/FeeManager/FeeManager.js")
);
const ForgotPassword = lazy(
  () => import("./scenes/ForgotPassword/ForgotPassword.js")
);
const Home = lazy(() => import("./scenes/Home/Home.js"));
const HospitalityManager = lazy(
  () =>
    import(
      "./scenes/Admin/scenes/TournamentManager/CompetitionManager/Competition/components/HospitalityManager/HospitalityManager.tsx"
    )
);
const HospitalityRegistration = lazy(
  () =>
    import(
      "scenes/Home/scenes/HospitalityRegistration/HospitalityRegistration.tsx"
    )
);
const Judging = lazy(() => import("./scenes/Judging/Judging.js"));
const JudgingHome = lazy(
  () => import("scenes/Judging/scenes/JudgingHome/JudgingHome.js")
);
const JudgingLogin = lazy(
  () => import("scenes/Judging/components/JudgingLogin/JudgingLogin.js")
);
const Login = lazy(() => import("./scenes/Login/Login.js"));
const MasterSheet = lazy(
  () =>
    import(
      "scenes/Admin/scenes/TournamentManager/CompetitionManager/Competition/components/MasterSheet/MasterSheet.js"
    )
);
const MovementsManager = lazy(
  () => import("./scenes/Admin/scenes/MovementsManager.tsx")
);
const MyResults = lazy(
  () => import("scenes/Home/scenes/MyResults/MyResults.js")
);
const NanduForm = lazy(() => import("components/NanduForm/NanduForm.tsx"));
const NanduManager = lazy(
  () => import("./scenes/Admin/scenes/NanduManager/NanduManager.js")
);
const NanduJudgingHome = lazy(
  () => import("./scenes/Judging/scenes/NanduJudgingHome.tsx")
);
const NanduRulesetsManager = lazy(
  () =>
    import("scenes/Admin/scenes/NanduRulesetsManager/NanduRulesetsManager.tsx")
);
const NanduScoresManager = lazy(
  () => import("./scenes/Admin/scenes/NanduScoresManager.tsx")
);
const NotFound = lazy(() => import("./scenes/NotFound/NotFound.js"));
const Participant = lazy(
  () =>
    import(
      "./scenes/Home/scenes/Participants/components/Participant/Participant.js"
    )
);
const ParticipantManager = lazy(
  () => import("scenes/Admin/scenes/ParticipantManager.tsx")
);
const Participants = lazy(
  () => import("./scenes/Home/scenes/Participants/Participants.js")
);
const ParticipantSchedule = lazy(
  () =>
    import(
      "./scenes/Schedule/components/ParticipantSchedule/ParticipantSchedule.js"
    )
);
const ParticipantVideos = lazy(
  () => import("components/ParticipantVideos/ParticipantVideos.jsx")
);
const ParticipantView = lazy(
  () => import("./scenes/Admin/scenes/ParticipantView/ParticipantView.js")
);
const PrintingStation = lazy(
  () =>
    import(
      "scenes/Admin/scenes/TournamentManager/CompetitionManager/Competition/components/PrintingStation/PrintingStation.js"
    )
);
// const PrintNanduForm = lazy(
//   () => import("./scenes/NanduForm/components/PrintNanduForm")
// );
const Register = lazy(() => import("./scenes/Register/Register.js"));
const Reports = lazy(
  () =>
    import(
      "scenes/Admin/scenes/TournamentManager/CompetitionManager/Competition/components/Reports/Reports.js"
    )
);
const ResetPassword = lazy(
  () => import("./scenes/ResetPassword/ResetPassword.js")
);
const RestrictedUserManager = lazy(
  () =>
    import(
      "scenes/Admin/scenes/TournamentManager/CompetitionManager/Competition/components/RestrictedUserManager.tsx"
    )
);
const Ring = lazy(
  () =>
    import(
      "./scenes/Admin/scenes/TournamentManager/CompetitionManager/Competition/components/Rings/components/Ring/Ring.js"
    )
);
const Rings = lazy(
  () =>
    import(
      "scenes/Admin/scenes/TournamentManager/CompetitionManager/Competition/components/Rings/Rings.js"
    )
);
const RulesetNanduManager = lazy(
  () =>
    import(
      "scenes/Admin/scenes/NanduRulesetsManager/RulesetNanduManager/RulesetNanduManager.tsx"
    )
);
const Schedule = lazy(() => import("./scenes/Schedule/Schedule.js"));
const ScheduleHome = lazy(
  () => import("./scenes/Schedule/components/ScheduleHome/ScheduleHome.js")
);
const Team = lazy(() => import("scenes/Admin/scenes/TeamManager/Team.js"));
const TeamManager = lazy(
  () => import("./scenes/Admin/scenes/TeamManager/TeamManager.js")
);
// const Tickets = lazy(() => import("./scenes/Home/scenes/Tickets"));
const TicketManager = lazy(
  () =>
    import(
      "scenes/Admin/scenes/TournamentManager/CompetitionManager/TicketManager/TicketManager.js"
    )
);
const Tournament = lazy(
  () => import("scenes/Admin/scenes/TournamentManager/Tournament/Tournament.js")
);
const TournamentManager = lazy(
  () => import("./scenes/Admin/scenes/TournamentManager/TournamentManager.js")
);
const TournamentReports = lazy(
  () =>
    import(
      "scenes/Admin/scenes/TournamentManager/TournamentReports/TournamentReports.js"
    )
);
const User = lazy(
  () => import("./scenes/Admin/scenes/UserManager/User/User.js")
);
const UserManager = lazy(
  () => import("./scenes/Admin/scenes/UserManager/UserManager.js")
);
const VideoPlayer = lazy(() => import("./scenes/VideoPlayer/VideoPlayer.js"));
const WebRouter = lazy(
  () => import("scenes/ClientDashboard/ClientDashboard.tsx")
);
const WebsiteMessageManager = lazy(
  () =>
    import(
      "./scenes/Admin/scenes/WebsiteMessageManager/WebsiteMessageManager.js"
    )
);
const WushuStyleManager = lazy(
  () => import("./scenes/Admin/scenes/WushuStyleManager/WushuStyleManager.js")
);

function getParticipantRoutes(isAdmin = false) {
  const participantRoutes = [
    {
      index: true,
      element: <EventsPane isAdmin={isAdmin} />,
    },
    {
      path: "events",
      element: <EventsPane isAdmin={isAdmin} />,
    },
    {
      path: "info",
      element: (
        <EditParticipant
          navigateToAfterDelete={isAdmin ? -1 : "/participants"}
        />
      ),
    },
    {
      path: "video-submissions",
      element: <ParticipantVideos />,
    },
    {
      path: "schedule",
      element: <ParticipantSchedule />,
    },
    { path: "nandu-forms", element: <NanduForm /> },
    { path: "results", element: <MyResults /> },
  ];

  return participantRoutes;
}

function getRoutes({ isAdmin = false, isJudge = false }) {
  const routes: RouteObject[] = [
    {
      path: "/",
      element: <WebRouter />,
      children: [
        {
          path: "*",
          element: <Home />,
          children: [
            { index: true, element: <Navigate to="participants" /> },
            { path: "account", element: <Account /> },
            { path: "lots", element: <DrawLots /> },
            {
              path: "participants",
              element: (
                <>
                  <Outlet />
                </>
              ),
              children: [
                { index: true, element: <Participants /> },
                {
                  path: ":participantId",
                  element: <Participant />,
                  children: getParticipantRoutes(),
                },
              ],
            },
            { path: "hospitality", element: <HospitalityRegistration /> },
            // {
            //   path: "nanduform",
            //   element: <NanduForm />,
            //   children: [
            //     { path: "*", element: <EditNanduForm /> },
            //     { path: "print", element: <PrintNanduForm /> },
            //   ],
            // },
            // { path: "tickets", element: <Tickets /> },
            {
              path: "checkout",
              children: [{ index: true, element: <Checkout /> }],
            },
            { path: "*", element: <NotFound /> },
          ],
        },
        // { path: "about", element: <About /> },
        { path: "all-results", element: <AllResultsHome /> },
        { path: "forgotPassword", element: <ForgotPassword /> },
        { path: "login", element: <Login /> },
        { path: "register", element: <Register /> },
        { path: "resetPassword/:token", element: <ResetPassword /> },
        {
          path: "schedule",
          element: <Schedule />,
          children: [
            { index: true, element: <ScheduleHome isAdmin={isAdmin} /> },
            { path: ":participantId/events", element: <ParticipantSchedule /> },
          ],
        },
        { path: "video/:eventParticipationId", element: <VideoPlayer /> },
      ],
    },
    {
      path: "adm",
      element: <Admin />,
      children: [
        { index: true, element: <AdminLogin /> },
        {
          path: "home",
          element: <AdminHome />,
          children: [
            { index: true, element: <Navigate to="tournaments" /> },
            {
              path: "tournaments",
              element: (
                <>
                  <Outlet />
                </>
              ),
              children: [
                {
                  index: true,
                  element: <TournamentManager />,
                },
                {
                  path: ":tournamentId",
                  element: <Tournament />,
                  children: [
                    {
                      index: true,
                      element: <CompetitionManager />,
                    },
                    {
                      path: "rings",
                      element: (
                        <>
                          <Outlet />
                        </>
                      ),
                      children: [
                        {
                          index: true,
                          element: <Rings />,
                        },
                        {
                          path: ":ringId",
                          element: <Ring />,
                        },
                      ],
                    },
                    { path: "tickets", element: <TicketManager /> },
                    { path: "printingStation", element: "TODO" },
                    { path: "hospitality", element: "TODO" },
                    { path: "athletes", element: "TODO" },
                    { path: "reports", element: <TournamentReports /> },
                  ],
                },
                {
                  path: ":tournamentId/competitions/:competitionId",
                  element: <Competition />,
                  children: [
                    {
                      index: true,
                      element: <CompetitionEdit />,
                    },
                    {
                      path: "championshipSetup",
                      element: <ChampionshipSetup />,
                    },
                    {
                      path: "ageGroups",
                      element: <AgeGroups />,
                    },
                    {
                      path: "eventRegistrationRestrictions",
                      element: <EventCategoryRestrictions />,
                    },
                    {
                      path: "masterSheet",
                      element: (
                        <>
                          <Outlet />
                        </>
                      ),
                      children: [
                        {
                          index: true,
                          element: <MasterSheet />,
                        },
                        {
                          path: "batch-events",
                          element: <BatchEvents />,
                        },
                      ],
                    },
                    {
                      path: "participants",
                      element: (
                        <>
                          <Outlet />
                        </>
                      ),
                      children: [
                        { index: true, element: <AthleteManager /> },
                        {
                          path: ":participantId",
                          element: <ParticipantView />,
                          children: getParticipantRoutes(true),
                        },
                      ],
                    },
                    {
                      path: "competitionEvents",
                      element: <CompetitionEvents />,
                    },
                    {
                      path: "printingStation",
                      element: <PrintingStation />,
                    },
                    {
                      path: "reports",
                      element: <Reports />,
                    },
                    {
                      path: "awards",
                      element: <AllResultsHome />,
                    },
                    {
                      path: "restricted-users",
                      element: <RestrictedUserManager />,
                    },
                    {
                      path: "hospitality",
                      element: <HospitalityManager />,
                    },
                  ],
                },
              ],
            },
            {
              path: "users",
              element: (
                <>
                  <Outlet />
                </>
              ),
              children: [
                { index: true, element: <UserManager /> },
                {
                  path: ":userId",
                  element: (
                    <>
                      <Outlet />
                    </>
                  ),
                  children: [
                    { index: true, element: <User /> },
                    {
                      path: "participants",
                      element: (
                        <>
                          <h3>Participant View</h3>
                          <hr />
                          <Outlet />
                        </>
                      ),
                      children: [
                        {
                          path: ":participantId",
                          element: <ParticipantView />,
                          children: getParticipantRoutes(true),
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: "participants",
              element: (
                <>
                  <Outlet />
                </>
              ),
              children: [
                { index: true, element: <ParticipantManager /> },
                {
                  path: ":participantId",
                  element: (
                    <>
                      <h3>Participant View</h3>
                      <hr />
                      <ParticipantView />
                    </>
                  ),
                  children: getParticipantRoutes(true),
                },
              ],
            },
            { path: "wushu-styles", element: <WushuStyleManager /> },
            { path: "website-messages", element: <WebsiteMessageManager /> },
            { path: "fees", element: <FeeManager /> },
            { path: "error-messages", element: <ErrorMessageManager /> },
            { path: "event-categories", element: <EventCategoryManager /> },
            { path: "events", element: <EventManager /> },
            {
              path: "teams",
              element: (
                <>
                  <Outlet />
                </>
              ),
              children: [
                { index: true, element: <TeamManager /> },
                { path: ":id", element: <Team /> },
              ],
            },
            // { path: "masterSheet", element: <MasterSheet /> },
            { path: "nandu", element: <NanduManager /> },
            { path: "nanduScores", element: <NanduScoresManager /> },
            {
              path: "nanduRulesets",
              element: (
                <>
                  <Outlet />
                </>
              ),
              children: [
                { index: true, element: <NanduRulesetsManager /> },
                { path: ":nanduRulesetId", element: <RulesetNanduManager /> },
              ],
            },
            { path: "movements", element: <MovementsManager /> },
            { path: "*", element: <NotFound /> },
          ],
        },
        { path: "*", element: <NotFound /> },
      ],
    },
    {
      path: "judging",
      element: <Judging />,
      children: [
        { index: true, element: <JudgingLogin /> },
        {
          path: "home",
          element: isJudge || isAdmin ? <JudgingHome /> : <Navigate to=".." />,
        },
        {
          path: "nandu",
          element:
            isJudge || isAdmin ? <NanduJudgingHome /> : <Navigate to=".." />,
        },
        {
          path: "display",
          element: isJudge || isAdmin ? <Display /> : <Navigate to=".." />,
        },
        { path: "*", element: <NotFound /> },
      ],
    },
    {
      path: "roll-call",
      element: <CheckIn />,
      children: [
        { index: true, element: <CheckInLogin /> },
        {
          path: "home",
          element:
            isJudge || isAdmin ? (
              <CheckInHome isAdmin={isAdmin} />
            ) : (
              <Navigate to=".." />
            ),
        },
        { path: "*", element: <NotFound /> },
      ],
    },
  ];

  return routes;
}

export default getRoutes;
