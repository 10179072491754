import { CSpinner } from "@coreui/react";
import { isTokenExpired } from "helpers";
import dispatchToast from "helpers/dispatchToast";
import { QueryCache, ReactQueryCacheProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { Suspense, useEffect } from "react";
import { AuthActions, AuthSelectors } from "redux/auth";
import { useAppDispatch, useAppSelector } from "redux/hooks";

import Routes from "./Routes";
import ToastContainer from "./ToastContainer";

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      retry: 1,
    },
  },
});

function App() {
  const dispatch = useAppDispatch();
  const token = useAppSelector(AuthSelectors.getAuthToken);

  // manually check for expired tokens every 5 seconds to mitigate duplicate payments
  useEffect(() => {
    const interval = setInterval(() => {
      if (token && isTokenExpired(token)) {
        dispatch(AuthActions.logout());
        dispatchToast({
          message: "Your session has expired, please try logging in again.",
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [token, dispatch]);

  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <ToastContainer />
      <BrowserRouter>
        <Suspense fallback={<CSpinner color="primary" />}>
          <Routes />
        </Suspense>
      </BrowserRouter>
    </ReactQueryCacheProvider>
  );
}

export default App;
