import type { AuthUser, LoginInput, RegisterInput } from "@repo/types/auth";

import { POST } from "../config";

export default {
  async register(userInput: RegisterInput): Promise<AuthUser> {
    const { user } = await POST("auth/register", { userInput });

    return user;
  },
  async login({
    emailOrUsername,
    password,
    isUsername,
    turnstileToken,
  }: LoginInput): Promise<AuthUser> {
    const { user } = await POST("auth/login", {
      emailOrUsername,
      isUsername,
      password,
      turnstileToken,
    });

    return user;
  },
};
